// To use Html5QrcodeScanner (more info below)
import { Html5QrcodeScanner } from "html5-qrcode";
class Reader {
  scanner: Html5QrcodeScanner;
  width: number;
  height: number;
  constructor(
    elementId: string,
    config: {
      width: number;
      height: number;
    },
    onScanSuccess: (decodedText: string) => void,
    onScanFailure: (error: any) => void
  ) {
    //   function onScanSuccess(decodedText, decodedResult) {
    //   // handle the scanned code as you like, for example:
    //     console.log(`Code matched = ${decodedText}`, decodedResult);
    //   }

    //   function onScanFailure(error) {
    //     // handle scan failure, usually better to ignore and keep scanning.
    //     // for example:
    //     console.warn(`Code scan error = ${error}`);
    //   }
    this.width = config.width;
    this.height = config.height;
    const html5QrcodeScanner = new Html5QrcodeScanner(
      elementId,
      { fps: 10, qrbox: { width: this.width, height: this.height } },
      /* verbose= */ false
    );
    html5QrcodeScanner.render(onScanSuccess, onScanFailure);
    this.scanner = html5QrcodeScanner;
  }
  stop() {
    this.scanner.clear();
  }
}

export { Reader };
